<template>
  <b-modal
    id="organization-form-modal"
    v-model="showModal"
    :title="isEdit? 'Edit Organization' : 'Create Organization'"
    centered
    hide-footer
    @shown="initModal"
  >
    <b-form
      class="mt-2"
      @submit.prevent="onSubmit"
    >
      <b-form-group
        class="mb-3 required"
        label="Name"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          type="text"
          placeholder="Type the organization name"
          :state="v$.form.name.$dirty ? (!v$.form.name.$error? null : false) : null"
        />
        <b-form-invalid-feedback
          v-if="v$.form.name.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.name.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
        <FormCharacterCounter
          class="float-end"
          :field="form.name"
          :max-length="v$.form.name.maxLength.$params.max"
        />
      </b-form-group>
      <b-form-group
        class="mb-4"
        label="Description"
        label-for="input-description"
      >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Type the organization description..."
          rows="3"
        />
        <b-form-invalid-feedback
          v-if="v$.form.description.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.description.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
        <FormCharacterCounter
          class="float-end"
          :field="form.description"
          :max-length="v$.form.description.maxLength.$params.max"
        />
      </b-form-group>
      <b-form-group
        class="mb-4 required"
        label="Timezone"
        label-for="input-timezone"
      >
        <FormInputSelect
          id="input-timezone"
          v-model="form.timezone"
          :options="timezoneOptions"
          :dropdown-only="true"
          placeholder="Select timezone"
          :is-error="v$.form.timezone.$error ? true : false"
        />
        <b-form-invalid-feedback
          v-if="v$.form.timezone.$error"
          class="d-inline"
        >
          <span
            v-for="(error, index) in v$.form.timezone.$errors"
            :key="index"
          >
            {{ error.$message + ' ' }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-between gap-2 mt-5">
        <b-button
          class="shadow-none"
          variant="soft-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="d-flex">
          <b-button
            type="submit"
            variant="primary"
          >
            Save
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { isEmptyObject } from '@/utils/helper';
import { timezoneOptions } from '@/utils/constant';

import FormInputSelect from '@/components/FormInputSelect.vue';
import FormCharacterCounter from '@/components/FormCharacterCounter.vue';
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

export default {
  components: {
    FormCharacterCounter,
    FormInputSelect
  },
  props: {
    organizationDetail: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const toast = useToast();
    return {
      toast,
      v$: useVuelidate()
    }
  },
  data() {
    return {
      timezoneOptions,
      form: {
        name: '',
        description: '',
        timezone: '',
      },
      showModal: false,
    };
  },
  computed: {
    isEdit() {
      return !isEmptyObject(this.organizationDetail);
    },
  },
  validations: {
    form: {
      name: {
        required: helpers.withMessage("Name field is required.", required),
        minLength: minLength(0),
        maxLength: helpers.withMessage("Maximum characters is 100.", maxLength(100)),
      },
      description: {
        maxLength: helpers.withMessage("Maximum characters is 300.", maxLength(300)),
      },
      timezone: {
        required: helpers.withMessage("Timezone field is required.", required),
      },
    },
  },
  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return
      }
      this.submitForm();
    },
    resetForm() {
      this.v$.$reset()
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.organizationDetail[key];
      });
    },
    async submitForm() {
      try {
        if (this.isEdit) {
          await this.$api.updateOrganization(this.organizationDetail.id, this.form);
          this.$emit('update-data', 'Edited');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Updated Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        } else {
          await this.$api.createOrganization(this.form);
          this.$emit('update-data', 'Saved');
          // toast
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Saved Successfully',
              icon: 'ri-check-double-line'
            }
          }
          this.toast(toastContent);
        }
        this.closeModal();
      } catch (error) {
        console.log(error);
        // toast
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process Failed',
          }
        }
        this.toast(toastContent);
      }
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    initModal() {
      this.v$.$reset()
      if (this.isEdit) {
        this.form = { ...this.organizationDetail };
      } else {
        this.resetForm();
      }
    },
  },
};
</script>
